:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-10037{width:100%;}
:root ._fg-0{flex-grow:0;}
:root ._pt-1481558214{padding-top:var(--space-7);}
:root ._pb-1481558183{padding-bottom:var(--space-8);}
:root ._bg-43817440{background-color:var(--color-20);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-43818401{color:var(--color-10);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016202{font-weight:var(--f-we-6);}
:root ._ls-167744121{letter-spacing:var(--f-21-6);}
:root ._fos-229441282{font-size:var(--f-si-6);}
:root ._lh-222976635{line-height:var(--f-li-6);}
:root ._tt-uppercase{text-transform:uppercase;}
:root ._pb-1481558307{padding-bottom:var(--space-4);}
:root ._fd-row{flex-direction:row;}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._fw-wrap{flex-wrap:wrap;}