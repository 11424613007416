:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._pos-absolute{position:absolute;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._w-10037{width:100%;}
:root ._h-10037{height:100%;}
:root ._t-1037{top:10%;}
:root ._pos-relative{position:relative;}
:root ._w-7037{width:70%;}
:root ._h-7037{height:70%;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._btlr-10000px{border-top-left-radius:10000px;}
:root ._btrr-10000px{border-top-right-radius:10000px;}
:root ._bbrr-10000px{border-bottom-right-radius:10000px;}
:root ._bblr-10000px{border-bottom-left-radius:10000px;}
:root ._t--537{top:-5%;}
:root ._t--337{top:-3%;}
:root ._w-7537{width:75%;}
:root ._h-7537{height:75%;}