:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._jc-flex-start{justify-content:flex-start;}
:root ._ai-center{align-items:center;}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._fg-1{flex-grow:1;}
:root ._fs-1{flex-shrink:1;}
:root ._w-10037{width:100%;}
:root ._zi-0{z-index:0;}
:root ._bg-43818153{background-color:var(--color-18);}
@media screen and (max-width: 1020px) { :root:root:root ._pb-_md_1316330176{padding-bottom:var(--space-12);} }
:root ._mih-100vh{min-height:100vh;}
:root ._h-10037{height:100%;}
:root ._pos-absolute{position:absolute;}
:root ._o-0d0t146{opacity:0.1;}
:root ._bg-43817440{background-color:var(--color-20);}
:root ._maw-330px{max-width:330px;}
:root ._dsp-none{display:none;}
@media screen and (min-width: 1021px) { :root:root:root ._dsp-_gtMd_flex{display:flex;} }