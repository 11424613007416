:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._maw-700px{max-width:700px;}
:root ._w-10037{width:100%;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._btw-1px{border-top-width:1px;}
:root ._brw-1px{border-right-width:1px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-1px{border-left-width:1px;}
:root ._btc-43818401{border-top-color:var(--color-10);}
:root ._brc-43818401{border-right-color:var(--color-10);}
:root ._bbc-43818401{border-bottom-color:var(--color-10);}
:root ._blc-43818401{border-left-color:var(--color-10);}
:root ._btlr-1307609967{border-top-left-radius:var(--radius-4);}
:root ._btrr-1307609967{border-top-right-radius:var(--radius-4);}
:root ._bbrr-1307609967{border-bottom-right-radius:var(--radius-4);}
:root ._bblr-1307609967{border-bottom-left-radius:var(--radius-4);}
:root ._bg-43817440{background-color:var(--color-20);}
:root ._pt-1481558152{padding-top:var(--space-9);}
:root ._pb-1481558152{padding-bottom:var(--space-9);}
:root ._pr-1481558276{padding-right:var(--space-5);}
:root ._pl-1481558276{padding-left:var(--space-5);}
:root ._jc-center{justify-content:center;}
:root ._ai-center{align-items:center;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-137133827{color:var(--color-1);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._ls-167744152{letter-spacing:var(--f-21-7);}
:root ._fos-229441313{font-size:var(--f-si-7);}
:root ._lh-222976666{line-height:var(--f-li-7);}
:root ._ussel-auto{user-select:auto;-webkit-user-select:auto;}
:root ._ta-center{text-align:center;}
:root ._tt-uppercase{text-transform:uppercase;}
@media screen and (min-width: 801px) { :root:root:root ._col-_gtSm_137133827{color:var(--color-1);} }
@media screen and (min-width: 801px) { :root:root:root ._ff-_gtSm_299667014{font-family:var(--f-fa);} }
@media screen and (min-width: 801px) { :root:root:root ._fow-_gtSm_500{font-weight:500;} }
@media screen and (min-width: 801px) { :root:root:root ._ls-_gtSm_167744183{letter-spacing:var(--f-21-8);} }
@media screen and (min-width: 801px) { :root:root:root ._fos-_gtSm_229441344{font-size:var(--f-si-8);} }
@media screen and (min-width: 801px) { :root:root:root ._lh-_gtSm_222976697{line-height:var(--f-li-8);} }