:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-1{flex-shrink:1;}
:root ._btc-43818153{border-top-color:var(--color-18);}
:root ._brc-43818153{border-right-color:var(--color-18);}
:root ._bbc-43818153{border-bottom-color:var(--color-18);}
:root ._blc-43818153{border-left-color:var(--color-18);}
:root ._btw-0px{border-top-width:0px;}
:root ._brw-0px{border-right-width:0px;}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._blw-0px{border-left-width:0px;}
:root ._fg-1{flex-grow:1;}
:root ._h-0px{height:0px;}
:root ._mah-0px{max-height:0px;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._bts-solid{border-top-style:solid;}
:root ._bls-solid{border-left-style:solid;}
:root ._brs-solid{border-right-style:solid;}
:root ._transform-d0t1394298074{transform:translateY(-0.5px);}
:root ._fd-row{flex-direction:row;}
:root ._fs-0{flex-shrink:0;}
:root ._maw-330px{max-width:330px;}
:root ._mr-auto{margin-right:auto;}
:root ._ml-auto{margin-left:auto;}
:root ._mt-auto{margin-top:auto;}
:root ._fw-wrap{flex-wrap:wrap;}
:root ._pt-1481558338{padding-top:var(--space-3);}
:root ._w-10037{width:100%;}
:root ._jc-2055030478{justify-content:space-evenly;}