:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._btc-43818401{border-top-color:var(--color-10);}
:root ._brc-43818401{border-right-color:var(--color-10);}
:root ._bbc-43818401{border-bottom-color:var(--color-10);}
:root ._blc-43818401{border-left-color:var(--color-10);}
:root ._bbw-1px{border-bottom-width:1px;}
:root ._w-10037{width:100%;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._pos-relative{position:relative;}
:root ._bbs-solid{border-bottom-style:solid;}
:root ._maw-7537{max-width:75%;}
@media screen and (min-width: 801px) { :root:root:root ._maw-_gtSm_10037{max-width:100%;} }
@media screen and (min-width: 801px) { :root:root:root ._pr-_gtSm_1316330207{padding-right:var(--space-13);} }
@media screen and (min-width: 801px) { :root:root:root ._pl-_gtSm_1316330207{padding-left:var(--space-13);} }
@media screen and (min-width: 801px) { :root:root:root ._fd-_gtSm_row{flex-direction:row;} }
@media screen and (min-width: 801px) { :root:root:root:root ._fw-_gtSm_wrap{flex-wrap:wrap;} }
@media screen and (min-width: 801px) { :root:root:root:root:root ._fw-_gtSm_nowrap{flex-wrap:nowrap;} }
:root ._t-1px{top:1px;}
:root ._pl-1481558276{padding-left:var(--space-5);}
:root ._pr-1481558338{padding-right:var(--space-3);}
:root ._pl-1481558369{padding-left:var(--space-2);}
:root ._pr-1481558369{padding-right:var(--space-2);}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-43817440{color:var(--color-20);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016078{font-weight:var(--f-we-2);}
:root ._ls-1d0t2px46{letter-spacing:1.2px;}
:root ._fos-229441158{font-size:var(--f-si-2);}
:root ._lh-222976511{line-height:var(--f-li-2);}
:root ._tt-uppercase{text-transform:uppercase;}