:root ._dsp-inline{display:inline;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-137133889{color:var(--color-3);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-500{font-weight:500;}
:root ._tt-uppercase{text-transform:uppercase;}
:root ._ta-center{text-align:center;}
:root ._fos-229441251{font-size:var(--f-si-5);}
:root ._col-137134044{color:var(--color-8);}
:root ._fow-233016078{font-weight:var(--f-we-2);}
:root ._ls-167743997{letter-spacing:var(--f-21-2);}
:root ._fos-229441158{font-size:var(--f-si-2);}
:root ._lh-222976511{line-height:var(--f-li-2);}
:root ._ls-167744121{letter-spacing:var(--f-21-6);}
:root ._fos-229441282{font-size:var(--f-si-6);}
:root ._lh-222976635{line-height:var(--f-li-6);}