:root ._dsp-flex{display:flex;}
:root ._fd-row{flex-direction:row;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._h-28px{height:28px;}
:root ._w-50px{width:50px;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-43818401{color:var(--color-10);}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._dsp-none{display:none;}
:root ._fos-229441251{font-size:var(--f-si-5);}
:root ._tt-uppercase{text-transform:uppercase;}
:root ._fow-500{font-weight:500;}
@media screen and (min-width: 661px) { :root:root:root ._fos-_gtXs_229441313{font-size:var(--f-si-7);} }
@media screen and (min-width: 661px) { :root:root:root ._fow-_gtXs_500{font-weight:500;} }
@media screen and (min-width: 661px) { :root:root:root ._dsp-_gtXs_flex{display:flex;} }