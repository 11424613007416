:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._blw-2px{border-left-width:2px;}
:root ._blc-43818215{border-left-color:var(--color-16);}
:root ._pl-1481558307{padding-left:var(--space-4);}
:root ._bls-solid{border-left-style:solid;}
:root ._ai-center{align-items:center;}
:root ._jc-center{justify-content:center;}