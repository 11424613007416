:root ._dsp-flex{display:flex;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-relative{position:relative;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._ai-center{align-items:center;}
:root ._fd-row{flex-direction:row;}
:root ._gap-1316331261{gap:var(--space-26);}
:root ._ai-stretch{align-items:stretch;}
:root ._fd-column{flex-direction:column;}
:root ._zi-982430246{z-index:var(--zIndex-7);}
:root ._jc-center{justify-content:center;}
:root ._h-60px{height:60px;}
:root ._w-10037{width:100%;}
:root ._t-0px{top:0px;}
:root ._l-0px{left:0px;}
:root ._pos-absolute{position:absolute;}
:root ._bg-43818122{background-color:var(--color-19);}
:root ._pt-1481558307{padding-top:var(--space-4);}
:root ._pb-1481558307{padding-bottom:var(--space-4);}
:root ._pr-1481558307{padding-right:var(--space-4);}
:root ._pl-1481558307{padding-left:var(--space-4);}
:root ._mih-64px{min-height:64px;}
@media screen and (min-width: 1021px) { :root:root:root ._pr-_gtMd_1481558214{padding-right:var(--space-7);} }
@media screen and (min-width: 1021px) { :root:root:root ._pl-_gtMd_1481558214{padding-left:var(--space-7);} }
:root ._jc-441309761{justify-content:space-between;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._ff-299667014{font-family:var(--f-fa);}
:root ._fow-233016140{font-weight:var(--f-we-4);}
:root ._ls-167744059{letter-spacing:var(--f-21-4);}
:root ._fos-229441220{font-size:var(--f-si-4);}
:root ._lh-222976573{line-height:var(--f-li-4);}
:root ._col-675002279{color:var(--color);}
:root ._l--20px{left:-20px;}