:root ._ai-stretch{align-items:stretch;}
:root ._dsp-flex{display:flex;}
:root ._fd-column{flex-direction:column;}
:root ._fb-auto{flex-basis:auto;}
:root ._bxs-border-box{box-sizing:border-box;}
:root ._pos-absolute{position:absolute;}
:root ._mih-0px{min-height:0px;}
:root ._miw-0px{min-width:0px;}
:root ._fs-0{flex-shrink:0;}
:root ._w-1px{width:1px;}
:root ._h-1px{height:1px;}
:root ._mt--1px{margin-top:-1px;}
:root ._mr--1px{margin-right:-1px;}
:root ._mb--1px{margin-bottom:-1px;}
:root ._ml--1px{margin-left:-1px;}
:root ._zi--10000{z-index:-10000;}
:root ._ox-hidden{overflow-x:hidden;}
:root ._oy-hidden{overflow-y:hidden;}
:root ._o-1e-8{opacity:1e-8;}
:root ._pe-none{pointer-events:none !important;}
:root ._dsp-inline{display:inline;}
:root ._ww-break-word{word-wrap:break-word;}
:root ._mt-0px{margin-top:0px;}
:root ._mr-0px{margin-right:0px;}
:root ._mb-0px{margin-bottom:0px;}
:root ._ml-0px{margin-left:0px;}
:root ._col-675002279{color:var(--color);}